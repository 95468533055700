<template>
  <div class="m-login-form">
    <a href="/" class="m-login-form__logo-link">
      <img src="@/assets/logo_zone.svg" width="187" height="69" class="m-login-form__logo" />
    </a>
    <p class="m-login-form__suptitle">{{ $t('welcomeportal') }}</p>
    <h2 class="m-login-form__title">{{ $t('accessaccount') }}</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <input-gcr label="email" type="email" invalidText="emailrequired" v-model="username" :submitted="submitted" />
      </div>
      <div class="form-group">
        <password-gcr label="password" invalidText="passwordrequired" v-model="password" :submitted="submitted" />
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-8">
          <div class="form-group"><checkbox-gcr :label="$t('rememberme')" v-model="rememberMe" /></div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <a v-bind:href="forgotPasswordUrl" class="m-login-form__forgotpass">{{ $t('forgotpass') }}</a>
          </div>
        </div>
      </div>
      <button-gcr btnClass="m-login-form__btn btn-primary btn-block btn-arrow" label="login" :loading="loggingIn" />
    </form>
  </div>
</template>

<script>
import { Consts, Urls, User } from '@/_helpers'
import InputGcr from '@/components/atoms/Input'
import PasswordGcr from '@/components/atoms/Password'
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import ButtonGcr from '@/components/atoms/Button'
import alerts from '@/components/mixins/alerts'
import currentUserRoles from '@/graphql/users/getCurrentUserRoles.graphql'
import getAccreditations from '@/graphql/accreditations/getAccreditations.graphql'

require('../../utilities/form')

export default {
  mixins: [alerts],
  components: { InputGcr, PasswordGcr, CheckboxGcr, ButtonGcr },
  data() {
    return {
      username: this.$store.state.user.username,
      rememberMe: this.$store.state.user.rememberMe,
      password: '',
      submitted: false,
      loggingIn: false,
      forgotPasswordUrl: Urls.localizedUrl(Urls.FORGOT_PASSWORD)
    }
  },
  methods: {
    async handleSubmit() {
      this.submitted = true

      if (!this.username || !this.password)
        return

      this.loggingIn = true
      try {
        const returnPath = this.$route.query.returnPath || '/'
        await this.$store.dispatch(Consts.ACTION_USER_LOGIN, { username: this.username, password: this.password, rememberMe: this.rememberMe })
        const { accreditations } = await this.query(getAccreditations)
        const { currentUser: { roles: userRoles } } = await this.query(currentUserRoles)

        if (!accreditations.length && !User.validateHaveCustomerSupport(userRoles)) {
          this.showError('noAccreditations')
        } else if (accreditations.length === 1) {
          this.$store.commit(Consts.ACTION_ACCREDITATION_SELECT, accreditations[0].id)
          await this.$router.push(returnPath)
        } else {
          await this.$router.push(`${Urls.localizedUrl(Urls.SELECT_ACCREDITATIONS)}?returnPath=${returnPath}`)
        }
      } catch (error) {
        this.loggingIn = false
        this.showError(error === Consts.ERROR_INVALID_CREDENTIALS ? 'invalidCredentials' : 'unexpectedError')
      }
    },
    query(query) {
      return this.$apollo
        .query({ query, fetchPolicy: 'network-only' })
        .then(result => result.data)
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.m-login-form {
  &__forgotpass {
    font-size: 14px;
  }
  &__suptitle {
    color: gray('gray-400');
    font-weight: 500; //font-weight: 400;;
    margin: 0;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 16px;
  }
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__btn {
    margin-top: 24px;
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .m-login-form__logo-link {
    display: none;
  }
}
</style>
